<template>
  <div id="kt_app_content_container" class="app-container container-xxl">
    <!--begin::Layout-->
    <div class="d-flex flex-column flex-xl-row">
      <!--begin::Sidebar-->
      <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
        <!--begin::Card-->
        <div class="card mb-5 mb-xl-8">
          <!--begin::Card body-->
          <div class="card-body pt-15">
            <!--begin::Summary-->
            <div class="d-flex flex-center flex-column mb-5">
              <!--begin::Avatar-->
              <div class="symbol symbol-100px symbol-circle mb-7">
                <!--begin:: Avatar -->
                <div
                  class="symbol symbol-circle symbol-50px overflow-hidden me-3"
                >
                  <a
                    href="/metronic8/demo1/apps/user-management/users/view.html"
                  >
                    <div
                      class="symbol-label fs-1 bg-light-primary text-primary"
                    >
                      B
                    </div>
                  </a>
                </div>
                <!--end::Avatar-->
              </div>
              <!--end::Avatar-->

              <!--begin::Ad-->
              <a
                href="#"
                class="fs-3 text-gray-800 text-hover-primary fw-bold mb-1"
              >
                Buse Çallı
              </a>
              <!--end::Ad-->

              <!--begin::Badge-->
              <div class="badge badge-light-info d-inline mb-4">
                Software Engineer
              </div>
              <!--begin::Badge-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap flex-center">
                <!--begin::Stats-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    py-3
                    px-3
                    mb-3
                  "
                >
                  <div class="fs-4 fw-bold text-gray-700">
                    <span class="w-75px">9/10</span>
                    <i class="ki-duotone ki-arrow-up fs-3 text-success"
                      ><span class="path1"></span><span class="path2"></span
                    ></i>
                  </div>
                  <div class="fw-semibold text-muted">Beceri</div>
                </div>
                <!--end::Stats-->

                <!--begin::Stats-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    py-3
                    px-3
                    mx-4
                    mb-3
                  "
                >
                  <div class="fs-4 fw-bold text-gray-700">
                    <span class="w-50px">10/10</span>
                    <i class="ki-duotone ki-arrow-down fs-3 text-danger"
                      ><span class="path1"></span><span class="path2"></span
                    ></i>
                  </div>
                  <div class="fw-semibold text-muted">Heves</div>
                </div>
                <!--end::Stats-->

                <!--begin::Stats-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    py-3
                    px-3
                    mb-3
                  "
                >
                  <div class="fs-4 fw-bold text-gray-700">
                    <span class="w-50px">9/10</span>
                    <i class="ki-duotone ki-arrow-up fs-3 text-success"
                      ><span class="path1"></span><span class="path2"></span
                    ></i>
                  </div>
                  <div class="fw-semibold text-muted">Yetenek</div>
                </div>
                <!--end::Stats-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Summary-->

            <!--begin::Details toggle-->
            <div class="d-flex flex-stack fs-4 py-3">
              <span
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-original-title="Edit customer details"
                data-kt-initialized="1"
              >
                <a
                  href="#"
                  class="btn btn-sm btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_customer"
                >
                  Düzenle
                </a>
              </span>
              <span data-bs-toggle="tooltip" data-bs-trigger="hover">
                <a href="#" class="btn btn-sm btn-light-info"> CV İndir </a>
              </span>
              <span data-bs-toggle="tooltip" data-bs-trigger="hover">
                <a href="#" class="btn btn-sm btn-light-danger"> Adayı Sil </a>
              </span>
            </div>
            <!--end::Details toggle-->

            <div class="separator separator-dashed my-3"></div>

            <!--begin::Details content-->
            <div id="kt_customer_view_details" class="collapse show">
              <div class="py-5 fs-6">
                <!--begin::Details item-->
                <div class="fw-bold mt-5">E-posta</div>
                <div class="text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"
                    >busecalli25@gmail.com</a
                  >
                </div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Telefon</div>
                <div class="text-gray-600">+905359807641</div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Adres</div>
                <div class="text-gray-600">
                  Cevatpaşa Mahallesi<br />Çalışkan Sokak No 14 Daire 7<br />Istanbul,
                  Turkiye
                </div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Yabancı Dil</div>
                <div class="text-gray-600">İngilizce</div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">İş Tecrübesi</div>
                <div class="text-gray-600">2 yıl</div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Mezun Olduğu Bölüm</div>
                <div class="text-gray-600">Yazılım Mühendisliği</div>
                <!--begin::Details item-->
              </div>
            </div>
            <!--end::Details content-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </div>
      <!--end::Sidebar-->

      <!--begin::Content-->
      <div class="flex-lg-row-fluid ms-lg-15">
        <!--begin:::Tabs-->
        <ul
          class="
            nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x
            border-0
            fs-4
            fw-semibold
            mb-8
          "
          role="tablist"
        >
          <!--begin:::Tab item-->
          <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4 active"
              data-bs-toggle="tab"
              href="#kt_customer_view_overview_tab"
              aria-selected="true"
              role="tab"
              >Mülakatlar</a
            >
          </li>
          <!--end:::Tab item-->

          <!--begin:::Tab item-->
          <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4"
              data-bs-toggle="tab"
              href="#kt_customer_view_overview_events_and_logs_tab"
              aria-selected="false"
              role="tab"
              tabindex="-1"
              >CV İşlemleri</a
            >
          </li>
          <!--end:::Tab item-->

          <!--begin:::Tab item-->
          <li class="nav-item ms-auto">
            <!--begin::Action menu-->
            <a
              href="#"
              class="btn btn-primary ps-7"
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              İşlemler
              <i class="ki-duotone ki-down fs-2 me-0"></i>
            </a>
            <!--begin::Menu-->
            <div
              class="
                menu
                menu-sub
                menu-sub-dropdown
                menu-column
                menu-rounded
                menu-gray-800
                menu-state-bg-light-primary
                fw-semibold
                py-4
                w-250px
                fs-6
              "
              data-kt-menu="true"
            >
              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <a href="#" class="menu-link px-5"> CV İndir </a>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <a href="#" class="menu-link px-5"> Yeni Mülakat Gönder </a>
              </div>
              <!--end::Menu item-->

              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <a href="#" class="menu-link text-danger px-5"> Adayı Sil </a>
              </div>
              <!--end::Menu item-->
            </div>
            <!--end::Menu-->
            <!--end::Menu-->
          </li>
          <!--end:::Tab item-->
        </ul>
        <!--end:::Tabs-->

        <!--begin:::Tab content-->
        <div class="tab-content" id="myTabContent">
          <!--begin:::Tab pane-->
          <div
            class="tab-pane fade active show"
            id="kt_customer_view_overview_tab"
            role="tabpanel"
          >
            <!--begin::Card-->
            <div class="card pt-4 mb-6 mb-xl-9">
              <!--begin::Card header-->
              <div class="card-header border-0">
                <!--begin::Card title-->
                <div class="card-title">
                  <h2>Mülakatlar</h2>
                </div>
                <!--end::Card title-->

                <!--begin::Card toolbar-->
                <div class="card-toolbar">
                  <!--begin::Filter-->
                  <button
                    type="button"
                    class="btn btn-sm btn-flex btn-light-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_add_payment"
                  >
                    <i class="ki-duotone ki-plus-square fs-3"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></i>
                    Mülakat Daveti Gönder
                  </button>
                  <!--end::Filter-->
                </div>
                <!--end::Card toolbar-->
              </div>
              <!--end::Card header-->

              <!--begin::Card body-->
              <div class="card-body pt-0 pb-5">
                <!--begin::Table-->
                <div
                  id="kt_table_customers_payment_wrapper"
                  class="dt-container dt-bootstrap5 dt-empty-footer"
                >
                  <div id="" class="table-responsive">
                    <table
                      class="table align-middle table-row-dashed gy-5 dataTable"
                      id="kt_table_customers_payment"
                      style="width: 100%"
                    >
                      <colgroup>
                        <col data-dt-column="0" style="width: 120px" />
                        <col data-dt-column="1" style="width: 251.9531px" />
                        <col data-dt-column="2" style="width: 100.546px" />
                        <col data-dt-column="3" style="width: 50.7031px" />
                        <col data-dt-column="4" style="width: 150.75px" />
                      </colgroup>
                      <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                        <tr class="text-start text-muted text-uppercase gs-0">
                          <th
                            class="
                              min-w-100px
                              dt-orderable-asc dt-orderable-desc
                            "
                            data-dt-column="0"
                            rowspan="1"
                            colspan="1"
                            aria-label="Invoice No.: Activate to sort"
                            tabindex="0"
                          >
                            <span class="dt-column-title" role="button"
                              >Pozisyon Adı</span
                            ><span class="dt-column-order"></span>
                          </th>
                          <th
                            class="
                              min-w-100px
                              dt-orderable-asc dt-orderable-desc
                            "
                            data-dt-column="0"
                            rowspan="1"
                            colspan="1"
                            aria-label="Invoice No.: Activate to sort"
                            tabindex="0"
                          >
                            <span class="dt-column-title" role="button"
                              >Mülakat Başlığı</span
                            ><span class="dt-column-order"></span>
                          </th>
                          <th
                            data-dt-column="2"
                            rowspan="1"
                            colspan="1"
                            class="dt-orderable-asc dt-orderable-desc"
                            aria-label="Status: Activate to sort"
                            tabindex="0"
                          >
                            <span class="dt-column-title" role="button"
                              >Durum</span
                            ><span class="dt-column-order"></span>
                          </th>
                          <th
                            data-dt-column="3"
                            rowspan="1"
                            colspan="1"
                            class="
                              dt-type-numeric dt-orderable-asc dt-orderable-desc
                            "
                            aria-label="Amount: Activate to sort"
                            tabindex="0"
                          >
                            <span class="dt-column-title" role="button"
                              >Uygunluk</span
                            ><span class="dt-column-order"></span>
                          </th>
                          <th
                            class="
                              min-w-100px
                              dt-orderable-asc dt-orderable-desc
                            "
                            data-dt-column="4"
                            rowspan="1"
                            colspan="1"
                            aria-label="Date: Activate to sort"
                            tabindex="0"
                          >
                            <span class="dt-column-title" role="button"
                              >Son İşlem Tarihi</span
                            ><span class="dt-column-order"></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="fs-6 fw-semibold text-gray-600">
                        <tr class="clickable-row" @click="goToInterviewDetail">
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >Hybrid Çalışacak Frontend Developer</a
                            >
                          </td>
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >08.01.2021 başlangıçlı mülakat</a
                            >
                          </td>
                          <td>
                            <span class="badge badge-light-success"
                              >Tamamlandı</span
                            >
                          </td>
                          <td class="dt-type-numeric">90%</td>
                          <td data-order="2020-12-14T20:43:00+03:00">
                            14 Aralık 2022, 18:43
                          </td>
                        </tr>
                        <tr class="clickable-row" @click="goToInterviewDetail">
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >Ofisten Çalışacak Fullstack Developer</a
                            >
                          </td>
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >01.01.2021 başlangıçlı mülakat</a
                            >
                          </td>
                          <td>
                            <span class="badge badge-light-success"
                              >Tamamlandı</span
                            >
                          </td>
                          <td class="dt-type-numeric">70%</td>
                          <td data-order="2020-12-01T10:12:00+03:00">
                            01 Aralık 2020, 10:12
                          </td>
                        </tr>
                        <tr class="clickable-row" @click="goToInterviewDetail">
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >Business Analyst</a
                            >
                          </td>
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >08.01.2021 başlangıçlı mülakat</a
                            >
                          </td>
                          <td>
                            <span class="badge badge-light-success"
                              >Tamamlandı</span
                            >
                          </td>
                          <td class="dt-type-numeric">93%</td>
                          <td data-order="2020-11-12T14:01:00+03:00">
                            11 Kasım 2020, 23:11
                          </td>
                        </tr>
                        <tr class="clickable-row" @click="goToInterviewDetail">
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >Ofisten Çalışacak Fullstack Developer</a
                            >
                          </td>
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >08.01.2021 başlangıçlı mülakat</a
                            >
                          </td>
                          <td>
                            <span class="badge badge-light-warning"
                              >Devam Ediyor</span
                            >
                          </td>
                          <td class="dt-type-numeric"></td>
                          <td data-order="2020-10-21T17:54:00+03:00">
                            21 Ekim 2020, 15:54
                          </td>
                        </tr>
                        <tr class="clickable-row" @click="goToInterviewDetail">
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >Business Analyst</a
                            >
                          </td>
                          <td>
                            <a
                              href="#"
                              class="text-gray-600 text-hover-primary mb-1"
                              >08.01.2021 başlangıçlı mülakat</a
                            >
                          </td>
                          <td>
                            <span class="badge badge-light-success"
                              >Tamamlandı</span
                            >
                          </td>
                          <td class="dt-type-numeric">95%</td>
                          <td data-order="2020-10-19T07:32:00+03:00">
                            19 Eylül 2020, 17:32
                          </td>
                        </tr>
                      </tbody>
                      <!--end::Table body-->
                      <tfoot></tfoot>
                    </table>
                  </div>
                  <div id="" class="row">
                    <div
                      id=""
                      class="
                        col-sm-12 col-md-5
                        d-flex
                        align-items-center
                        justify-content-center justify-content-md-start
                        dt-toolbar
                      "
                    ></div>
                    <div
                      id=""
                      class="
                        col-sm-12 col-md-7
                        d-flex
                        align-items-center
                        justify-content-center justify-content-md-end
                      "
                    >
                      <div class="dt-paging paging_simple_numbers">
                        <nav aria-label="pagination">
                          <ul class="pagination">
                            <li class="dt-paging-button page-item disabled">
                              <button
                                class="page-link previous"
                                role="link"
                                type="button"
                                aria-controls="kt_table_customers_payment"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous"
                                tabindex="-1"
                              >
                                <i class="previous"></i>
                              </button>
                            </li>
                            <li class="dt-paging-button page-item active">
                              <button
                                class="page-link"
                                role="link"
                                type="button"
                                aria-controls="kt_table_customers_payment"
                                aria-current="page"
                                data-dt-idx="0"
                              >
                                1
                              </button>
                            </li>
                            <li class="dt-paging-button page-item">
                              <button
                                class="page-link"
                                role="link"
                                type="button"
                                aria-controls="kt_table_customers_payment"
                                data-dt-idx="1"
                              >
                                2
                              </button>
                            </li>
                            <li class="dt-paging-button page-item">
                              <button
                                class="page-link next"
                                role="link"
                                type="button"
                                aria-controls="kt_table_customers_payment"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <i class="next"></i>
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Table-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end:::Tab pane-->

          <!--begin:::Tab pane-->
          <div
            class="tab-pane fade"
            id="kt_customer_view_overview_events_and_logs_tab"
            role="tabpanel"
          >
            <!--begin::Card-->
            <div class="card pt-4 mb-6 mb-xl-9">
              <!--begin::Card header-->
              <div class="card-header border-0">
                <!--begin::Card title-->
                <div class="card-title">
                  <h2>Cv Yükle</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->

              <!--begin::Card body-->
              <div class="card-body py-0">
                <div class="mb-2">
                  <label class="form-label required">Cv Yükle</label>
                  <input
                    type="file"
                    name="candidateCv"
                    class="form-control form-control-lg form-control-solid"
                  />
                </div>
                <div class="text-gray-600 fw-bold fs-8 mb-10">
                  Sadece pdf uzantılı dosyalar (maks 2mb)
                </div>
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end:::Tab pane-->
        </div>
        <!--end:::Tab content-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Layout-->

    <!--begin::Modals-->
    <!--begin::Modal - Add Payment-->
    <div
      class="modal fade"
      id="kt_modal_add_payment"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header">
            <!--begin::Modal title-->
            <h2 class="fw-bold">Add a Payment Record</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              id="kt_modal_add_payment_close"
              class="btn btn-icon btn-sm btn-active-icon-primary"
            >
              <i class="ki-duotone ki-cross fs-1"
                ><span class="path1"></span><span class="path2"></span
              ></i>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <!--begin::Form-->
            <form
              id="kt_modal_add_payment_form"
              class="form fv-plugins-bootstrap5 fv-plugins-framework"
              action="#"
            >
              <!--begin::Input group-->
              <div class="fv-row mb-7 fv-plugins-icon-container">
                <!--begin::Label-->
                <label class="fs-6 fw-semibold form-label mb-2">
                  <span class="required">Invoice Number</span>

                  <span
                    class="ms-2"
                    data-bs-toggle="tooltip"
                    aria-label="The invoice number must be unique."
                    data-bs-original-title="The invoice number must be unique."
                    data-kt-initialized="1"
                  >
                    <i class="ki-duotone ki-information fs-7"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></i>
                  </span>
                </label>
                <!--end::Label-->

                <!--begin::Input-->
                <input
                  type="text"
                  class="form-control form-control-solid"
                  name="invoice"
                  value=""
                />
                <!--end::Input-->
                <div
                  class="
                    fv-plugins-message-container
                    fv-plugins-message-container--enabled
                    invalid-feedback
                  "
                ></div>
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="fv-row mb-7 fv-plugins-icon-container">
                <!--begin::Label-->
                <label class="required fs-6 fw-semibold form-label mb-2"
                  >Status</label
                >
                <!--end::Label-->

                <!--begin::Input-->
                <select
                  class="
                    form-select form-select-solid
                    fw-bold
                    select2-hidden-accessible
                  "
                  name="status"
                  data-control="select2"
                  data-placeholder="Select an option"
                  data-hide-search="true"
                  data-select2-id="select2-data-9-csf1"
                  tabindex="-1"
                  aria-hidden="true"
                  data-kt-initialized="1"
                >
                  <option data-select2-id="select2-data-11-6lxm"></option>
                  <option value="0">Approved</option>
                  <option value="1">Pending</option>
                  <option value="2">Rejected</option>
                  <option value="3">In progress</option>
                  <option value="4">Completed</option></select
                ><span
                  class="
                    select2 select2-container select2-container--bootstrap5
                  "
                  dir="ltr"
                  data-select2-id="select2-data-10-65jy"
                  style="width: 100%"
                  ><span class="selection"
                    ><span
                      class="
                        select2-selection select2-selection--single
                        form-select form-select-solid
                        fw-bold
                      "
                      role="combobox"
                      aria-haspopup="true"
                      aria-expanded="false"
                      tabindex="0"
                      aria-disabled="false"
                      aria-labelledby="select2-status-wt-container"
                      aria-controls="select2-status-wt-container"
                      ><span
                        class="select2-selection__rendered"
                        id="select2-status-wt-container"
                        role="textbox"
                        aria-readonly="true"
                        title="Select an option"
                        ><span class="select2-selection__placeholder"
                          >Select an option</span
                        ></span
                      ><span
                        class="select2-selection__arrow"
                        role="presentation"
                        ><b role="presentation"></b></span></span></span
                  ><span class="dropdown-wrapper" aria-hidden="true"></span
                ></span>
                <!--end::Input-->
                <div
                  class="
                    fv-plugins-message-container
                    fv-plugins-message-container--enabled
                    invalid-feedback
                  "
                ></div>
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="fv-row mb-7 fv-plugins-icon-container">
                <!--begin::Label-->
                <label class="required fs-6 fw-semibold form-label mb-2"
                  >Invoice Amount</label
                >
                <!--end::Label-->

                <!--begin::Input-->
                <input
                  type="text"
                  class="form-control form-control-solid"
                  name="amount"
                  value=""
                />
                <!--end::Input-->
                <div
                  class="
                    fv-plugins-message-container
                    fv-plugins-message-container--enabled
                    invalid-feedback
                  "
                ></div>
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="fv-row mb-15">
                <!--begin::Label-->
                <label class="fs-6 fw-semibold form-label mb-2">
                  <span class="required">Additional Information</span>

                  <span
                    class="ms-2"
                    data-bs-toggle="tooltip"
                    aria-label="Information such as description of invoice or product purchased."
                    data-bs-original-title="Information such as description of invoice or product purchased."
                    data-kt-initialized="1"
                  >
                    <i class="ki-duotone ki-information fs-7"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></i>
                  </span>
                </label>
                <!--end::Label-->

                <!--begin::Input-->
                <textarea
                  class="form-control form-control-solid rounded-3"
                  name="additional_info"
                ></textarea>
                <!--end::Input-->
              </div>
              <!--end::Input group-->

              <!--begin::Actions-->
              <div class="text-center">
                <button
                  type="reset"
                  id="kt_modal_add_payment_cancel"
                  class="btn btn-light me-3"
                >
                  Vazgeç
                </button>

                <button
                  type="submit"
                  id="kt_modal_add_payment_submit"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> Güncelle </span>
                  <span class="indicator-progress">
                    Lütfen bekleyiniz...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
    <!--end::Modal - New Card--><!--begin::Modal - Adjust Balance-->
    <div
      class="modal fade"
      id="kt_modal_adjust_balance"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header">
            <!--begin::Modal title-->
            <h2 class="fw-bold">Adjust Balance</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              id="kt_modal_adjust_balance_close"
              class="btn btn-icon btn-sm btn-active-icon-primary"
            >
              <i class="ki-duotone ki-cross fs-1"
                ><span class="path1"></span><span class="path2"></span
              ></i>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <!--begin::Balance preview-->
            <div class="d-flex text-center mb-9">
              <div
                class="
                  w-50
                  border border-dashed border-gray-300
                  rounded
                  mx-2
                  p-4
                "
              >
                <div class="fs-6 fw-semibold mb-2 text-muted">
                  Current Balance
                </div>
                <div
                  class="fs-2 fw-bold"
                  kt-modal-adjust-balance="current_balance"
                >
                  US$ 32,487.57
                </div>
              </div>
              <div
                class="
                  w-50
                  border border-dashed border-gray-300
                  rounded
                  mx-2
                  p-4
                "
              >
                <div class="fs-6 fw-semibold mb-2 text-muted">
                  New Balance

                  <span
                    class="ms-2"
                    data-bs-toggle="tooltip"
                    aria-label="Enter an amount to preview the new balance."
                    data-bs-original-title="Enter an amount to preview the new balance."
                    data-kt-initialized="1"
                  >
                    <i class="ki-duotone ki-information fs-7"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span
                    ></i>
                  </span>
                </div>
                <div class="fs-2 fw-bold" kt-modal-adjust-balance="new_balance">
                  --
                </div>
              </div>
            </div>
            <!--end::Balance preview-->

            <!--begin::Form-->
            <form
              id="kt_modal_adjust_balance_form"
              class="form fv-plugins-bootstrap5 fv-plugins-framework"
              action="#"
            >
              <!--begin::Input group-->
              <div class="fv-row mb-7 fv-plugins-icon-container">
                <!--begin::Label-->
                <label class="required fs-6 fw-semibold form-label mb-2"
                  >Adjustment type</label
                >
                <!--end::Label-->

                <!--begin::Dropdown-->
                <select
                  class="
                    form-select form-select-solid
                    fw-bold
                    select2-hidden-accessible
                  "
                  name="adjustment"
                  aria-label="Select an option"
                  data-control="select2"
                  data-dropdown-parent="#kt_modal_adjust_balance"
                  data-placeholder="Select an option"
                  data-hide-search="true"
                  data-select2-id="select2-data-12-pbpx"
                  tabindex="-1"
                  aria-hidden="true"
                  data-kt-initialized="1"
                >
                  <option data-select2-id="select2-data-14-zovm"></option>
                  <option value="1">Credit</option>
                  <option value="2">Debit</option></select
                ><span
                  class="
                    select2 select2-container select2-container--bootstrap5
                  "
                  dir="ltr"
                  data-select2-id="select2-data-13-h8yd"
                  style="width: 100%"
                  ><span class="selection"
                    ><span
                      class="
                        select2-selection select2-selection--single
                        form-select form-select-solid
                        fw-bold
                      "
                      role="combobox"
                      aria-haspopup="true"
                      aria-expanded="false"
                      tabindex="0"
                      aria-disabled="false"
                      aria-labelledby="select2-adjustment-2y-container"
                      aria-controls="select2-adjustment-2y-container"
                      ><span
                        class="select2-selection__rendered"
                        id="select2-adjustment-2y-container"
                        role="textbox"
                        aria-readonly="true"
                        title="Select an option"
                        ><span class="select2-selection__placeholder"
                          >Select an option</span
                        ></span
                      ><span
                        class="select2-selection__arrow"
                        role="presentation"
                        ><b role="presentation"></b></span></span></span
                  ><span class="dropdown-wrapper" aria-hidden="true"></span
                ></span>
                <!--end::Dropdown-->
                <div
                  class="
                    fv-plugins-message-container
                    fv-plugins-message-container--enabled
                    invalid-feedback
                  "
                ></div>
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="fv-row mb-7 fv-plugins-icon-container">
                <!--begin::Label-->
                <label class="required fs-6 fw-semibold form-label mb-2"
                  >Amount</label
                >
                <!--end::Label-->

                <!--begin::Input-->
                <input
                  id="kt_modal_inputmask"
                  type="text"
                  class="form-control form-control-solid"
                  name="amount"
                  value=""
                  inputmode="text"
                />
                <!--end::Input-->
                <div
                  class="
                    fv-plugins-message-container
                    fv-plugins-message-container--enabled
                    invalid-feedback
                  "
                ></div>
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="fv-row mb-7">
                <!--begin::Label-->
                <label class="fs-6 fw-semibold form-label mb-2"
                  >Add adjustment note</label
                >
                <!--end::Label-->

                <!--begin::Input-->
                <textarea
                  class="form-control form-control-solid rounded-3 mb-5"
                ></textarea>
                <!--end::Input-->
              </div>
              <!--end::Input group-->

              <!--begin::Disclaimer-->
              <div class="fs-7 text-muted mb-15">
                Please be aware that all manual balance changes will be audited
                by the financial team every fortnight. Please maintain your
                invoices and receipts until then. Thank you.
              </div>
              <!--end::Disclaimer-->

              <!--begin::Actions-->
              <div class="text-center">
                <button
                  type="reset"
                  id="kt_modal_adjust_balance_cancel"
                  class="btn btn-light me-3"
                >
                  Vazgeç
                </button>

                <button
                  type="submit"
                  id="kt_modal_adjust_balance_submit"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> Güncelle </span>
                  <span class="indicator-progress">
                    Lütfen bekleyiniz...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
    <!--end::Modal - New Card--><!--begin::Modal - New Address-->
    <div
      class="modal fade"
      id="kt_modal_update_customer"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Form-->
          <form class="form" action="#" id="kt_modal_update_customer_form">
            <!--begin::Modal header-->
            <div class="modal-header" id="kt_modal_update_customer_header">
              <!--begin::Modal title-->
              <h2 class="fw-bold">Aday Düzenle</h2>
              <!--end::Modal title-->

              <!--begin::Close-->
              <div
                id="kt_modal_update_customer_close"
                class="btn btn-icon btn-sm btn-active-icon-primary"
              >
                <i class="ki-duotone ki-cross fs-1"
                  ><span class="path1"></span><span class="path2"></span
                ></i>
              </div>
              <!--end::Close-->
            </div>
            <!--end::Modal header-->

            <!--begin::Modal body-->
            <div class="modal-body py-10 px-lg-17">
              <!--begin::Scroll-->
              <div
                class="d-flex flex-column scroll-y me-n7 pe-7"
                id="kt_modal_update_customer_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_update_customer_header"
                data-kt-scroll-wrappers="#kt_modal_update_customer_scroll"
                data-kt-scroll-offset="300px"
                style="max-height: 541px"
              >
                <!--begin::Notice-->

                <!--begin::Notice-->
                <div
                  class="
                    notice
                    d-flex
                    bg-light-primary
                    rounded
                    border-primary border border-dashed
                    mb-9
                    p-6
                  "
                >
                  <!--begin::Icon-->
                  <i class="ki-duotone ki-information fs-2tx text-primary me-4"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span
                  ></i>
                  <!--end::Icon-->

                  <!--begin::Wrapper-->
                  <div class="d-flex flex-stack flex-grow-1">
                    <!--begin::Content-->
                    <div class="fw-semibold">
                      <div class="fs-6 text-gray-700">
                        Aday düzenleme işlemlerinde veri gizliliği kapsamında ve
                        KVKK dahilinde işlem yapılmaktadır.
                      </div>
                    </div>
                    <!--end::Content-->
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Notice-->
                <!--end::Notice-->

                <!--begin::User toggle-->
                <div
                  class="fw-bold fs-3 rotate collapsible mb-7"
                  data-bs-toggle="collapse"
                  href="#kt_modal_update_customer_user_info"
                  role="button"
                  aria-expanded="false"
                  aria-controls="kt_modal_update_customer_user_info"
                >
                  Aday Bilgileri
                  <span class="ms-2 rotate-180">
                    <i class="ki-duotone ki-down fs-3"></i>
                  </span>
                </div>
                <!--end::User toggle-->

                <!--begin::User form-->
                <div
                  id="kt_modal_update_customer_user_info"
                  class="collapse show"
                >
                  <!--begin::Input group-->
                  <div class="mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">
                      <span>Profil Fotoğrafı</span>

                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        aria-label="Allowed file types: png, jpg, jpeg."
                        data-bs-original-title="Allowed file types: png, jpg, jpeg."
                        data-kt-initialized="1"
                      >
                        <i class="ki-duotone ki-information fs-7"
                          ><span class="path1"></span><span class="path2"></span
                          ><span class="path3"></span
                        ></i>
                      </span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Image input wrapper-->
                    <div class="mt-1">
                      <!--begin::Image input-->
                      <div
                        class="image-input image-input-outline"
                        data-kt-image-input="true"
                        style="
                          background-image: url('/metronic8/demo1/assets/media/svg/avatars/blank.svg');
                        "
                      >
                        <!--begin::Preview existing avatar-->
                        <div
                          class="image-input-wrapper w-125px h-125px"
                          style="
                            background-image: url(/metronic8/demo1/assets/media/avatars/300-1.jpg);
                          "
                        ></div>
                        <!--end::Preview existing avatar-->

                        <!--begin::Edit-->
                        <label
                          class="
                            btn btn-icon btn-circle btn-active-color-primary
                            w-25px
                            h-25px
                            bg-body
                            shadow
                          "
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          aria-label="Change avatar"
                          data-bs-original-title="Change avatar"
                          data-kt-initialized="1"
                        >
                          <i class="ki-duotone ki-pencil fs-7"
                            ><span class="path1"></span
                            ><span class="path2"></span
                          ></i>
                          <!--begin::Inputs-->
                          <input
                            type="file"
                            name="avatar"
                            accept=".png, .jpg, .jpeg"
                          />
                          <input type="hidden" name="avatar_remove" />
                          <!--end::Inputs-->
                        </label>
                        <!--end::Edit-->

                        <!--begin::Cancel-->
                        <span
                          class="
                            btn btn-icon btn-circle btn-active-color-primary
                            w-25px
                            h-25px
                            bg-body
                            shadow
                          "
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          aria-label="Cancel avatar"
                          data-bs-original-title="Cancel avatar"
                          data-kt-initialized="1"
                        >
                          <i class="ki-duotone ki-cross fs-2"
                            ><span class="path1"></span
                            ><span class="path2"></span
                          ></i>
                        </span>
                        <!--end::Cancel-->

                        <!--begin::Remove-->
                        <span
                          class="
                            btn btn-icon btn-circle btn-active-color-primary
                            w-25px
                            h-25px
                            bg-body
                            shadow
                          "
                          data-kt-image-input-action="remove"
                          data-bs-toggle="tooltip"
                          aria-label="Remove avatar"
                          data-bs-original-title="Remove avatar"
                          data-kt-initialized="1"
                        >
                          <i class="ki-duotone ki-cross fs-2"
                            ><span class="path1"></span
                            ><span class="path2"></span
                          ></i>
                        </span>
                        <!--end::Remove-->
                      </div>
                      <!--end::Image input-->
                    </div>
                    <!--end::Image input wrapper-->
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">Ad</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="name"
                      value="Buse Çallı"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">
                      <span>E-posta</span>

                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        aria-label="E-posta address must be active"
                        data-bs-original-title="E-posta address must be active"
                        data-kt-initialized="1"
                      >
                        <i class="ki-duotone ki-information fs-7"
                          ><span class="path1"></span><span class="path2"></span
                          ><span class="path3"></span
                        ></i>
                      </span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="email"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="email"
                      value="busecalli25@gmail.com"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">Telefon</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="telephone"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">Adres</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="address"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">Yabancı Dil</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="language"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">İş Tecrübesi</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="experience"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2"
                      >Mezun Olduğu Bölüm</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="university"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                </div>
                <!--end::User form-->
              </div>
              <!--end::Scroll-->
            </div>
            <!--end::Modal body-->

            <!--begin::Modal footer-->
            <div class="modal-footer flex-center">
              <!--begin::Button-->
              <button
                type="reset"
                id="kt_modal_update_customer_cancel"
                class="btn btn-light me-3"
              >
                Vazgeç
              </button>
              <!--end::Button-->

              <!--begin::Button-->
              <button
                type="submit"
                id="kt_modal_update_customer_submit"
                class="btn btn-primary"
              >
                <span class="indicator-label"> Güncelle </span>
                <span class="indicator-progress">
                  Lütfen bekleyiniz...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Modal footer-->
          </form>
          <!--end::Form-->
        </div>
      </div>
    </div>
    <!--end::Modal - New Address--><!--begin::Modal - New Card-->
    <div
      class="modal fade"
      id="kt_modal_new_card"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header">
            <!--begin::Modal title-->
            <h2>Add New Card</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <i class="ki-duotone ki-cross fs-1"
                ><span class="path1"></span><span class="path2"></span
              ></i>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <!--begin::Form-->
            <form
              id="kt_modal_new_card_form"
              class="form fv-plugins-bootstrap5 fv-plugins-framework"
              action="#"
            >
              <!--begin::Input group-->
              <div
                class="d-flex flex-column mb-7 fv-row fv-plugins-icon-container"
              >
                <!--begin::Label-->
                <label
                  class="
                    d-flex
                    align-items-center
                    fs-6
                    fw-semibold
                    form-label
                    mb-2
                  "
                >
                  <span class="required">Ad On Card</span>

                  <span
                    class="ms-1"
                    data-bs-toggle="tooltip"
                    aria-label="Specify a card holder's name"
                    data-bs-original-title="Specify a card holder's name"
                    data-kt-initialized="1"
                  >
                    <i class="ki-duotone ki-information-5 text-gray-500 fs-6"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span></i
                  ></span>
                </label>
                <!--end::Label-->

                <input
                  type="text"
                  class="form-control form-control-solid"
                  placeholder=""
                  name="card_name"
                  value="Max Doe"
                />
                <div
                  class="
                    fv-plugins-message-container
                    fv-plugins-message-container--enabled
                    invalid-feedback
                  "
                ></div>
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div
                class="d-flex flex-column mb-7 fv-row fv-plugins-icon-container"
              >
                <!--begin::Label-->
                <label class="required fs-6 fw-semibold form-label mb-2"
                  >Card Number</label
                >
                <!--end::Label-->

                <!--begin::Input wrapper-->
                <div class="position-relative">
                  <!--begin::Input-->
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Enter card number"
                    name="card_number"
                    value="4111 1111 1111 1111"
                  />
                  <!--end::Input-->

                  <!--begin::Card logos-->
                  <div
                    class="
                      position-absolute
                      translate-middle-y
                      top-50
                      end-0
                      me-5
                    "
                  >
                    <img
                      src="/metronic8/demo1/assets/media/svg/card-logos/visa.svg"
                      alt=""
                      class="h-25px"
                    />
                    <img
                      src="/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg"
                      alt=""
                      class="h-25px"
                    />
                    <img
                      src="/metronic8/demo1/assets/media/svg/card-logos/american-express.svg"
                      alt=""
                      class="h-25px"
                    />
                  </div>
                  <!--end::Card logos-->
                </div>
                <!--end::Input wrapper-->
                <div
                  class="
                    fv-plugins-message-container
                    fv-plugins-message-container--enabled
                    invalid-feedback
                  "
                ></div>
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-10">
                <!--begin::Col-->
                <div class="col-md-8 fv-row">
                  <!--begin::Label-->
                  <label class="required fs-6 fw-semibold form-label mb-2"
                    >Expiration Date</label
                  >
                  <!--end::Label-->

                  <!--begin::Row-->
                  <div class="row fv-row fv-plugins-icon-container">
                    <!--begin::Col-->
                    <div class="col-6">
                      <select
                        name="card_expiry_month"
                        class="
                          form-select form-select-solid
                          select2-hidden-accessible
                        "
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Month"
                        data-select2-id="select2-data-18-wg4b"
                        tabindex="-1"
                        aria-hidden="true"
                        data-kt-initialized="1"
                      >
                        <option data-select2-id="select2-data-20-4u85"></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option></select
                      ><span
                        class="
                          select2
                          select2-container
                          select2-container--bootstrap5
                        "
                        dir="ltr"
                        data-select2-id="select2-data-19-rl59"
                        style="width: 100%"
                        ><span class="selection"
                          ><span
                            class="
                              select2-selection select2-selection--single
                              form-select form-select-solid
                            "
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabindex="0"
                            aria-disabled="false"
                            aria-labelledby="select2-card_expiry_month-9m-container"
                            aria-controls="select2-card_expiry_month-9m-container"
                            ><span
                              class="select2-selection__rendered"
                              id="select2-card_expiry_month-9m-container"
                              role="textbox"
                              aria-readonly="true"
                              title="Month"
                              ><span class="select2-selection__placeholder"
                                >Month</span
                              ></span
                            ><span
                              class="select2-selection__arrow"
                              role="presentation"
                              ><b role="presentation"></b></span></span></span
                        ><span
                          class="dropdown-wrapper"
                          aria-hidden="true"
                        ></span
                      ></span>
                      <div
                        class="
                          fv-plugins-message-container
                          fv-plugins-message-container--enabled
                          invalid-feedback
                        "
                      ></div>
                    </div>
                    <!--end::Col-->

                    <!--begin::Col-->
                    <div class="col-6">
                      <select
                        name="card_expiry_year"
                        class="
                          form-select form-select-solid
                          select2-hidden-accessible
                        "
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Year"
                        data-select2-id="select2-data-21-byrz"
                        tabindex="-1"
                        aria-hidden="true"
                        data-kt-initialized="1"
                      >
                        <option data-select2-id="select2-data-23-85t8"></option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                        <option value="2031">2031</option>
                        <option value="2032">2032</option>
                        <option value="2033">2033</option>
                        <option value="2034">2034</option></select
                      ><span
                        class="
                          select2
                          select2-container
                          select2-container--bootstrap5
                        "
                        dir="ltr"
                        data-select2-id="select2-data-22-7u5g"
                        style="width: 100%"
                        ><span class="selection"
                          ><span
                            class="
                              select2-selection select2-selection--single
                              form-select form-select-solid
                            "
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabindex="0"
                            aria-disabled="false"
                            aria-labelledby="select2-card_expiry_year-hc-container"
                            aria-controls="select2-card_expiry_year-hc-container"
                            ><span
                              class="select2-selection__rendered"
                              id="select2-card_expiry_year-hc-container"
                              role="textbox"
                              aria-readonly="true"
                              title="Year"
                              ><span class="select2-selection__placeholder"
                                >Year</span
                              ></span
                            ><span
                              class="select2-selection__arrow"
                              role="presentation"
                              ><b role="presentation"></b></span></span></span
                        ><span
                          class="dropdown-wrapper"
                          aria-hidden="true"
                        ></span
                      ></span>
                      <div
                        class="
                          fv-plugins-message-container
                          fv-plugins-message-container--enabled
                          invalid-feedback
                        "
                      ></div>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Row-->
                </div>
                <!--end::Col-->

                <!--begin::Col-->
                <div class="col-md-4 fv-row fv-plugins-icon-container">
                  <!--begin::Label-->
                  <label
                    class="
                      d-flex
                      align-items-center
                      fs-6
                      fw-semibold
                      form-label
                      mb-2
                    "
                  >
                    <span class="required">CVV</span>

                    <span
                      class="ms-1"
                      data-bs-toggle="tooltip"
                      aria-label="Enter a card CVV code"
                      data-bs-original-title="Enter a card CVV code"
                      data-kt-initialized="1"
                    >
                      <i class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span></i
                    ></span>
                  </label>
                  <!--end::Label-->

                  <!--begin::Input wrapper-->
                  <div class="position-relative">
                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      minlength="3"
                      maxlength="4"
                      placeholder="CVV"
                      name="card_cvv"
                    />
                    <!--end::Input-->

                    <!--begin::CVV icon-->
                    <div
                      class="
                        position-absolute
                        translate-middle-y
                        top-50
                        end-0
                        me-3
                      "
                    >
                      <i class="ki-duotone ki-credit-cart fs-2hx"
                        ><span class="path1"></span><span class="path2"></span
                      ></i>
                    </div>
                    <!--end::CVV icon-->
                  </div>
                  <!--end::Input wrapper-->
                  <div
                    class="
                      fv-plugins-message-container
                      fv-plugins-message-container--enabled
                      invalid-feedback
                    "
                  ></div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="d-flex flex-stack">
                <!--begin::Label-->
                <div class="me-5">
                  <label class="fs-6 fw-semibold form-label"
                    >Save Card for further billing?</label
                  >
                  <div class="fs-7 fw-semibold text-muted">
                    If you need more info, please check budget planning
                  </div>
                </div>
                <!--end::Label-->

                <!--begin::Switch-->
                <label
                  class="
                    form-check form-switch form-check-custom form-check-solid
                  "
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    checked="checked"
                  />
                  <span class="form-check-label fw-semibold text-muted">
                    Save Card
                  </span>
                </label>
                <!--end::Switch-->
              </div>
              <!--end::Input group-->

              <!--begin::Actions-->
              <div class="text-center pt-15">
                <button
                  type="reset"
                  id="kt_modal_new_card_cancel"
                  class="btn btn-light me-3"
                >
                  Vazgeç
                </button>

                <button
                  type="submit"
                  id="kt_modal_new_card_submit"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> Güncelle </span>
                  <span class="indicator-progress">
                    Lütfen bekleyiniz...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
    <!--end::Modal - New Card--><!--end::Modals-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { hideModal } from "@/presentation/helper/Dom";
import { JobPostingListModel } from "@/domain/interview/job-posting-list/model/JobPostingListModel";
import { DeleteJobPostingModel } from "@/domain/interview/delete-job-posting/model/DeleteJobPostingModel";
import { UpdateJobPostingModel } from "@/domain/interview/update-job-posting/model/UpdateJobPostingModel";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  methods: {
    goToInterviewDetail() {
      this.$router.push("/interview/interview-detail");
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const interviewController =
      store.state.ControllersModule.interviewController;
    const jobPostingList = ref<JobPostingListModel[]>([]);
    const originalJobPostingList = ref<JobPostingListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const getInterviewList = async (page) => {
      isChanged.value = false;
      jobPostingList.value = [];
      originalJobPostingList.value = [];

      isLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interview: {},
        },
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      interviewController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((jobPosting) => {
              // totalItems.value = jobPosting.pagination.total;
              // currentPage.value = jobPosting.pagination.page;
              // pageCount.value = jobPosting.pagination.pageCount;
              jobPostingList.value.push(jobPosting);
              originalJobPostingList.value.push(jobPosting);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const deleteInterview = (interviewId) => {
      const interviewDeleteModel: DeleteJobPostingModel = {
        id: interviewId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_INTERVIEW),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewController
            .deleteJobPosting(interviewDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                isChanged.value = true;
                Swal.fire({
                  title: "İşlem başarılı!",
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_INTERVIEW),
                  icon: "success",
                }).then(() => {});
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateJobPostingDate = () => {
      const jobPostingUpdateModel: UpdateJobPostingModel = {};
      jobPostingUpdateModel.id = selectedInterviewId.value;
      jobPostingUpdateModel.endDate = new Date(newEndDate.value);

      interviewController
        .updateJobPosting(jobPostingUpdateModel)
        .then((response) => {
          if (response.isSuccess) {
            isChanged.value = true;

            Swal.fire({
              title: "İşlem başarılı!",
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_INTERVIEW),
              icon: "success",
            }).finally(() => {
              hideModal(changeDateRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const updatePollToClosed = async (id?: number) => {
      // const jobPostingUpdateModel: UpdatePollModel = {};
      // jobPostingUpdateModel.id = id;
      // jobPostingUpdateModel.completed = true;
      // Swal.fire({
      //   title: "Emin misiniz?",
      //   text: "Bu anketi sonlandırmak ister misiniz?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   buttonsStyling: false,
      //   confirmButtonText: "Tamam",
      //   cancelButtonText: "İptal",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //     cancelButton: "btn fw-bold btn-light-danger",
      //   },
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     interviewController
      //   .updatePoll(jobPostingUpdateModel)
      //   .then((response) => {
      //     if (response.isSuccess) {
      //       isChanged.value = true;
      //       Swal.fire({
      //         title: "İşlem başarılı!",
      //         text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_POLL),
      //         icon: "success",
      //       })
      //     } else {
      //       swalNotification.error(
      //         response.error.cause.cause +
      //           t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
      //           response.error.code +
      //           response.error.cause.code,
      //         t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      //       );
      //     }
      //   })
      //   .catch((e) => {
      //     swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
      //   });
      //   }
      // });
    };

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate
        ? new Date(oldDate).toISOString().split("T")[0]
        : "";
      selectedInterviewId.value = id;
    };

    watch(isChanged, (newValue) => {
      if (newValue) {
        getInterviewList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getInterviewList(1);
    });

    return {
      url,
      jobPostingList,
      deleteInterview,
      form,
      newEndDate,
      updateJobPostingDate,
      updatePollToClosed,
      changeDateRef,
      selectInterview,
      isLoading,
    };
  },
});
</script>

<style>
.clickable-row {
  cursor: pointer; /* Tıklanabilirlik özelliği */
  transition: background-color 0.3s ease; /* Arka plan renginin yumuşak geçişi */
}

.clickable-row:hover {
  background-color: #f0f0f0; /* Hover durumunda gri arka plan */
}

/* İstenirse satır metni de renklendirilebilir */
.clickable-row a {
  color: #333;
  text-decoration: none;
}

.clickable-row a:hover {
  color: #007bff; /* Linkin üzerine gelindiğinde renk değişikliği */
}
</style>
