
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { hideModal } from "@/presentation/helper/Dom";
import { JobPostingListModel } from "@/domain/interview/job-posting-list/model/JobPostingListModel";
import { DeleteJobPostingModel } from "@/domain/interview/delete-job-posting/model/DeleteJobPostingModel";
import { UpdateJobPostingModel } from "@/domain/interview/update-job-posting/model/UpdateJobPostingModel";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  methods: {
    goToInterviewDetail() {
      this.$router.push("/interview/interview-detail");
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const interviewController =
      store.state.ControllersModule.interviewController;
    const jobPostingList = ref<JobPostingListModel[]>([]);
    const originalJobPostingList = ref<JobPostingListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref("");
    const selectedInterviewId = ref<number>();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const getInterviewList = async (page) => {
      isChanged.value = false;
      jobPostingList.value = [];
      originalJobPostingList.value = [];

      isLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interview: {},
        },
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      interviewController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((jobPosting) => {
              // totalItems.value = jobPosting.pagination.total;
              // currentPage.value = jobPosting.pagination.page;
              // pageCount.value = jobPosting.pagination.pageCount;
              jobPostingList.value.push(jobPosting);
              originalJobPostingList.value.push(jobPosting);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const deleteInterview = (interviewId) => {
      const interviewDeleteModel: DeleteJobPostingModel = {
        id: interviewId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_INTERVIEW),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          interviewController
            .deleteJobPosting(interviewDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                isChanged.value = true;
                Swal.fire({
                  title: "İşlem başarılı!",
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_INTERVIEW),
                  icon: "success",
                }).then(() => {});
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const updateJobPostingDate = () => {
      const jobPostingUpdateModel: UpdateJobPostingModel = {};
      jobPostingUpdateModel.id = selectedInterviewId.value;
      jobPostingUpdateModel.endDate = new Date(newEndDate.value);

      interviewController
        .updateJobPosting(jobPostingUpdateModel)
        .then((response) => {
          if (response.isSuccess) {
            isChanged.value = true;

            Swal.fire({
              title: "İşlem başarılı!",
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_INTERVIEW),
              icon: "success",
            }).finally(() => {
              hideModal(changeDateRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const updatePollToClosed = async (id?: number) => {
      // const jobPostingUpdateModel: UpdatePollModel = {};
      // jobPostingUpdateModel.id = id;
      // jobPostingUpdateModel.completed = true;
      // Swal.fire({
      //   title: "Emin misiniz?",
      //   text: "Bu anketi sonlandırmak ister misiniz?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   buttonsStyling: false,
      //   confirmButtonText: "Tamam",
      //   cancelButtonText: "İptal",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //     cancelButton: "btn fw-bold btn-light-danger",
      //   },
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     interviewController
      //   .updatePoll(jobPostingUpdateModel)
      //   .then((response) => {
      //     if (response.isSuccess) {
      //       isChanged.value = true;
      //       Swal.fire({
      //         title: "İşlem başarılı!",
      //         text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_POLL),
      //         icon: "success",
      //       })
      //     } else {
      //       swalNotification.error(
      //         response.error.cause.cause +
      //           t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
      //           response.error.code +
      //           response.error.cause.code,
      //         t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      //       );
      //     }
      //   })
      //   .catch((e) => {
      //     swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
      //   });
      //   }
      // });
    };

    const selectInterview = (id?: number, oldDate?: Date) => {
      newEndDate.value = oldDate
        ? new Date(oldDate).toISOString().split("T")[0]
        : "";
      selectedInterviewId.value = id;
    };

    watch(isChanged, (newValue) => {
      if (newValue) {
        getInterviewList(1);
      }
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getInterviewList(1);
    });

    return {
      url,
      jobPostingList,
      deleteInterview,
      form,
      newEndDate,
      updateJobPostingDate,
      updatePollToClosed,
      changeDateRef,
      selectInterview,
      isLoading,
    };
  },
});
